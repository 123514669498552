import React from 'react';

class AudioCommentary extends React.Component {

  state = { playingAudio: false, showTranscript: false, audio: {} }

  togglePlay = () => {
    this.setState({
      playingAudio: !this.state.playingAudio
    })
  }

  toggleTranscript = () => {
    this.setState({
      showTranscript: !this.state.showTranscript
    })
  }

  componentDidMount() {
    this.setState({
      audio: new Audio(this.props.post.frontmatter.audio.publicURL)
    })
  }

  playAudio = () => {
    this.setState({ playingAudio: true })
    this.state.audio.play();
  }

  pauseAudio = () => {
    this.setState({ playingAudio: false })
    this.state.audio.pause();
  }

  render() {
    const playStateMsg = this.state.playingAudio ? "Pause Commentary" : "Play Commentary";
    const transcriptOpen = this.state.showTranscript ? "open" : "";
    const transcriptStateMsg = this.state.showTranscript ? "Hide Transcript" : "View Transcript";

      return (
        <div className="commentary">
          <button onClick={this.state.playingAudio ? this.pauseAudio : this.playAudio}>{playStateMsg}</button>
          <button className="transcript-link" onClick={this.toggleTranscript}>{transcriptStateMsg}</button>
          <div className={`transcript ${transcriptOpen}`}>
            <button className="transcript-close" onClick={this.toggleTranscript}>
              ✕
            </button>
            <div dangerouslySetInnerHTML={{ __html: this.props.post.html }}
            />
          </div>
        </div>
      )
  }
}

export default AudioCommentary
