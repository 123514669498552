import React from "react"
import AudioCommentary from "./audio"

const PosterSection = ({ post }) => (
  <div className={`poster ${post.frontmatter.landscape ? "landscape" : ""}`}>
    <img src={post.frontmatter.image.publicURL} alt={post.frontmatter.name} />
    <div className="caption">
      <h2>{post.frontmatter.name}</h2>
      {post.frontmatter.audio ? (
        <AudioCommentary post={post} />
      ) : ("")}
    </div>
  </div>
)

export default PosterSection
