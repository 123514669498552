import React from "react"
import { graphql } from "gatsby"
import PosterSection from "../components/posterSection"
import Header from "../components/header";
import Footer from "../components/footer"

const PostersPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.name)
    .map(edge => <PosterSection key={edge.node.id} post={edge.node} />)

  return (
    <div>
      <Header />
      <div className="content">
        <h1>Posters</h1>
        <div className="posters">
          {Posts}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default PostersPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\//posters/"} },
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            name
            landscape
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            audio {
              publicURL
            }
          }
        }
      }
    }
  }
`
